import type { IUser } from "@masterblaster/api";
import { HttpError, RestUtilities, get, getSwr, post, sendCommand } from "@masterblaster/api";
import * as Sentry from "@sentry/browser";
import { jwtDecode } from "jwt-decode";
import { TokenStore } from "./TokenStore";
import { isAfter } from "date-fns";
import type {
    ChangeEmailResponse,
    ChangePasswordResponse,
    ForgotPasswordResponse,
    GetTokenResponse,
    LoginResponse,
    LoginResponseData,
    RegisterResponse,
    ResetPasswordResponse,
    UserData,
    UserResourceAclEntry,
    VerifyCodeResponse,
} from "./types";

const getClaimValues = (type: string): string[] => {
    const token = TokenStore.getToken();
    if (!token) {
        return [];
    }

    try {
        const claims = jwtDecode<Record<string, unknown>>(token);
        const expiry = parseInt(claims["exp"] as string);
        if (!isNaN(expiry)) {
            const expiryDate = new Date(expiry * 1000);
            const expired = isAfter(new Date(), expiryDate);
            if (expired) {
                return [];
            }
        }
        const values = claims[type];
        if (Array.isArray(values)) {
            return values as string[];
        }

        return [values] as string[];
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setExtra("token", token);
            Sentry.captureException(err);
        });

        return [];
    }
};

export const getRoles = () => {
    return getClaimValues("http://schemas.microsoft.com/ws/2008/06/identity/claims/role");
};

export const getTenantRoles = (): string[] => {
    return getClaimValues("tenantRole");
};

export const SuperAdministratorRole = "SuperAdministrator";
export const AdministratorRole = "Administrator";
export const TournamentAdministrator = "TournamentAdministrator";
export const CommunityCreatorRole = "CommunityCreator";

export const TenantAdministratorRole = "TenantAdministrator";

export type Role =
    | typeof SuperAdministratorRole
    | typeof AdministratorRole
    | typeof TournamentAdministrator
    | typeof CommunityCreatorRole;

export const isMemberOfRoles = (roles: Role[] | undefined) => {
    if (roles === undefined) {
        return false;
    }

    return roles.some((x) => isMemberOf(x));
};

export const isMemberOf = (role: Role) => {
    const roles = getRoles();
    const idx = roles.indexOf(role);

    return idx !== -1;
};

export const hasTenantRole = (tenantCommunityId: string, role: string) => {
    const tenantRoles = getTenantRoles();
    return tenantRoles.some((x) => x === `${tenantCommunityId}:${role}`);
};

export const getEmail = () => {
    return getClaimValues("http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name")[0];
};

export const getFullName = () => {
    return getClaimValues("fullName")[0] || "";
};

export const isTournamentAdministrator = () => {
    return isMemberOf(TournamentAdministrator) || isMemberOf(AdministratorRole);
};

export const isSuperAdministrator = () => {
    return isMemberOf(SuperAdministratorRole);
};

export const isAdministrator = () => {
    return isMemberOf(AdministratorRole);
};

export const isTenantAdministrator = (tenantCommunityId: string) => {
    return hasTenantRole(tenantCommunityId, TenantAdministratorRole);
};

export const isSignedIn = () => {
    return !!TokenStore.getToken();
};

export const refreshToken = async (headers?: Headers) => {
    const result = await get<GetTokenResponse>("/api/authentication/getToken", {
        includeCredentials: true,
        showErrorToast: false,
        headers,
    });

    if (result.success && result.data.token) {
        TokenStore.setToken(result.data.token);
    }

    return result;
};

export const addLogin = async (loginProvider: string, returnUrl: string) => {
    const url = `/api/authentication/get_cookie`;
    const result = await get(url, { includeCredentials: true });
    const href = `/api/authentication/link_signin?provider=${loginProvider}&returnUrl=${returnUrl}`;
    window.location.href = href;
};

export const getOAuthProviders = () => getSwr<{ name: string; displayName: string }[]>(`api/authentication/providers`);

export const hasImpersonationContext = () => {
    return TokenStore.hasImpersonationContext();
};

export const setJwtToken = (token: string) => {
    TokenStore.setToken(token);
};

export const impersonatePlayer = async (playerId: string) => {
    const data = await get<LoginResponseData>(`api/authentication/impersonate?playerId=${playerId}`);
    TokenStore.impersonate(data);
    window.location.reload();
};

export const endImpersonation = () => {
    TokenStore.endImpersonation();
    window.location.reload();
};

export const resendConfirmationEmail = () => sendCommand("ResendConfirmationEmail", {});

export const getCurrentUser = async () => {
    try {
        const response = await get<UserData>(`/api/authentication/currentUser`, { showErrorToast: false });
        TokenStore.setUser(response.user);
        TokenStore.setAcl(response.acl);
        return response;
    } catch (err) {
        if (err instanceof HttpError && err.statusCode === 401) {
            TokenStore.removeToken();
            return null;
        }

        throw err;
    }
};

export const removeUserLogin = (playerId: string, loginProvider: string, providerKey: string) =>
    post(`api/authentication/remove_login`, { playerId, loginProvider, providerKey });

export const registerUser = (email: string, password: string) =>
    post<RegisterResponse>(`/api/authentication/register`, { email, password });

export const registerExternalUser = (email: string, returnUrl: string) =>
    post<{ errors: string[] }>(
        `/api/authentication/confirm_external_login`,
        { email, returnUrl },
        { includeCredentials: true, showErrorToast: false }
    );

export const signIn = (email: string, password: string) => {
    return RestUtilities.post<LoginResponse>(`/api/authentication/login`, { email, password }, false).then(
        (response) => {
            if (response.success && response.data && response.data.token) {
                setTokenStore(response.data.token, response.data.user, response.data.acl);
            }
            return response;
        }
    );
};

export const autoSignIn = (uid: string) => {
    return RestUtilities.post<LoginResponse>(`/api/authentication/autoLogin`, { uid }, false).then((response) => {
        if (response.success && response.data && response.data.token) {
            setTokenStore(response.data.token, response.data.user, []);
        }
        return response;
    });
};

export const verifyTwoFactor = (email: string, code: string) => {
    return RestUtilities.post<VerifyCodeResponse>(`/api/authentication/verifyTwoFactor`, { email, code }, false).then(
        (response) => {
            if (response.success && response.data && response.data.token) {
                setTokenStore(response.data.token, response.data.user, response.data.acl);
            }
            return response;
        }
    );
};

export const forgotPassword = (email: string): Promise<ForgotPasswordResponse> => {
    return RestUtilities.post<ForgotPasswordResponse>("/api/authentication/forgotpassword", { email }, false);
};

export const resetPassword = (email: string, password: string, code: string): Promise<ResetPasswordResponse> => {
    return RestUtilities.post<ResetPasswordResponse>(
        "/api/authentication/resetpassword",
        { email, password, code },
        false
    );
};

export const changeEmail = (email: string) => {
    return RestUtilities.post<ChangeEmailResponse>(`/api/authentication/changeemail`, { email }).then((response) => {
        if (response.success) {
            TokenStore.setToken(response.data.token);
        }
        return response;
    });
};

export const changePassword = (oldPassword: string, newPassword: string) => {
    return RestUtilities.post<ChangePasswordResponse>(`/api/authentication/changepassword`, {
        oldPassword,
        newPassword,
    });
};

const setTokenStore = (token: string, user: IUser, acls: UserResourceAclEntry[]) => {
    TokenStore.set(token, user, acls);
};
